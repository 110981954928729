import {Header} from "src/modules/common/ui/Header/Header";
import {MainBanner} from "../MainBanner/MainBanner";
import {NftDrop} from "../NftDrop/NftDrop";
import styles from "./MainPage.module.scss";
import {Footer} from "src/modules/common/ui/Footer/Footer";
import AddictiveGameMechanics from "../AddictiveGameMechanics/AddictiveGameMechanics";
import HowToEarn from "../HowToEarn/HowToEarn";
import FrogArea from "../FrogArea/FrogArea";
import AnimationShadow from "../AnimationShadow/AnimationShadow";

export const MainPage = () => {
    return (
        <div className={styles.content}>
            <Header />
            <MainBanner />
            <AddictiveGameMechanics/>
            <FrogArea/>
            <NftDrop />
            <HowToEarn/>
            <Footer />
        </div>
    )
}