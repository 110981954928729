import {useState} from "react";
import styles from "./Header.module.scss";
import arrow from "src/modules/landing/assets/img/svg/arrowDiagonal.svg";
import telegram from "src/modules/landing/assets/img/png/telegram.png";
import tiktok from "src/modules/landing/assets/img/png/tiktok.png";
import youtube from "src/modules/landing/assets/img/png/youtube.png";
import instagram from "src/modules/landing/assets/img/png/instagram.png";
import twitter from "src/modules/landing/assets/img/png/twitter.png";
import cl from "classnames";
import burgerMenu from "src/modules/landing/assets/img/svg/burgerMenu.svg";
import cross from "src/modules/landing/assets/img/svg/cross.svg"


export const Header = () => {
	const [isHover, setIsHover] = useState<boolean>(false);
	const [isMenu, setIsMenu] = useState<boolean>(false);
	const [isOpenMobileMenu, setIsOpenMobileMenu] = useState<boolean>(false)

	return (
		<div className={styles.content}>
			<img className={styles.burgerMenu} src={burgerMenu} alt={""} onClick={()=>{
				setIsOpenMobileMenu(!isOpenMobileMenu)
			}} onMouseLeave={() => {
				if (!isOpenMobileMenu) {
					setIsOpenMobileMenu(false);
				}
			}}/>
			<div className={styles.mainContent}>
				<div className={styles.btnJoinBlock}>
					<div
						className={styles.btnJoinArea}
						onMouseEnter={() => {
							setIsMenu(true);
						}}
						onMouseLeave={() => {
							if (!isHover) {
								setIsMenu(false);
							}
						}}
					></div>
					<div
						className={cl(styles.btnJoin, { [styles.btnJoinHover]: isMenu })}
					>
						<div>Join us</div>
						<div
							className={cl(styles.arrow, {
								[styles.active]: isMenu,
							})}
						>
							<svg
								width="14"
								height="9"
								viewBox="0 0 14 9"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0.875 0H2.625V0.9H3.5V1.8H4.375V2.7H5.25V3.6H6.125V4.5H7.875V3.6H8.75V2.7H9.625V1.8H10.5V0.9H11.375V0H13.125V0.9H14V2.7H13.125V3.6H12.25V4.5H11.375V5.4H10.5V6.3H9.625V7.2H8.75V8.1H7.875V9H6.125V8.1H5.25V7.2H4.375V6.3H3.5V5.4H2.625V4.5H1.75V3.6H0.875V2.7H0V0.9H0.875V0Z"
									fill="white"
								/>
							</svg>
						</div>
					</div>
					{isMenu && (
						<div
							className={styles.menu}
							onMouseEnter={() => setIsHover(true)}
							onMouseLeave={() => {
								setIsHover(false);
								setIsMenu(false);
							}}
						>
							<div className={styles.menuRow} onClick={()=>{
								window.open("https://t.me/sigmaversenews");
							}}>
								<img src={telegram} alt="" />
								<div>telegram</div>
							</div>
							<div className={styles.menuRow} onClick={()=>{
								window.open("https://x.com/sigmaversegame");
							}}>
								<img src={twitter} alt="" />
								<div>X</div>
							</div>
							<div className={styles.menuRow} onClick={()=>{
								window.open("https://instagram.com/sigmaversegame");
							}}>
								<img src={instagram} alt="" />
								<div>instagram</div>
							</div>
							<div className={styles.menuRow} onClick={()=>{
								window.open("https://tiktok.com/sigmaversegame");
							}}>
								<img src={tiktok} alt="" />
								<div>tiktok</div>
							</div>
							<div className={styles.menuRow} onClick={()=>{
								window.open("https://youtube.com/@sigmaversegame");
							}}>
								<img src={youtube} alt="" />
								<div>youtube</div>
							</div>
						</div>
					)}
				</div>
				{/*<div className={styles.btn} onClick={()=>{*/}
				{/*	window.open("https://jup.ag/swap/SOL-2Zaq3WyDJj2ZkMcuqUiTBfAXCjqZtqihxcwUdwv9dyHA")*/}
				{/*}}>buy $pepe</div>*/}
				<div className={styles.btn} onClick={()=>{
					window.open("https://t.me/pepexversebot/play")
				}}>next gen app</div>
				<div className={styles.btn} style={{opacity:"0.6", pointerEvents:"none"}}>sigmaverse</div>
			</div>
			<div className={styles.btnActive} onClick={()=>{
				window.open("https://t.me/nextgenpepe");
			}}>
				<div>join the club</div>
				<img src={arrow} alt=""/>
			</div>
			{isOpenMobileMenu &&
				<div className={styles.mobileMenu}>
					<img src={cross} alt={""} className={styles.cross} onClick={()=>{
						setIsOpenMobileMenu(false);
					}}/>
					<div className={styles.wrapBlock}>
						{/*<div className={styles.btn} onClick={()=>{*/}
						{/*	window.open("https://jup.ag/swap/SOL-2Zaq3WyDJj2ZkMcuqUiTBfAXCjqZtqihxcwUdwv9dyHA");*/}
						{/*}}>buy $pepe</div>*/}
						<div className={styles.btn} onClick={()=>{
							window.open("https://t.me/pepexversebot/play")
						}}>next gen app</div>
						<div className={styles.btn}>sigmaverse</div>
					</div>
					<div className={styles.socialNetworks}>
						<img src={telegram} alt="" onClick={()=>{
							window.open("https://t.me/sigmaversenews");
						}}/>
						<img src={twitter} alt="" onClick={()=>{
							window.open("https://x.com/sigmaversegame");
						}}/>
						<img src={instagram} alt="" onClick={()=>{
							window.open("https://instagram.com/sigmaversegame");
						}}/>
						<img src={tiktok} alt="" onClick={()=>{
							window.open("https://tiktok.com/sigmaversegame");
						}}/>
						<img src={youtube} alt="" onClick={()=>{
							window.open("https://youtube.com/@sigmaversegame");
						}}/>
					</div>
					<div className={styles.btnActive} onClick={()=>{
						window.open("https://t.me/nextgenpepe");
					}}>
						<div>join the club</div>
						<img src={arrow} alt=""/>
					</div>
				</div>}
		</div>
	);
};
