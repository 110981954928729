import React from 'react';
import styles from "./AnimationShadow.module.scss"

const AnimationShadow:React.FC<{className?:string}> = ({className}) => {
  return (
    <svg className={className} width="1022" height="628" viewBox="0 0 1022 628" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1_13" style={{maskType:"alpha"}}  maskUnits="userSpaceOnUse" x="0" y="0" width="1022" height="628">
        <rect width="1022" height="628" rx="12" fill="black"/>
      </mask>
      <g mask="url(#mask0_1_13)">
        <g filter="url(#filter0_f_1_13)">
          <path className={styles.path1} d="M430.444 -45.8287C407.028 33.7662 364.837 102.053 307.936 152.452C251.034 202.851 181.34 233.664 105.568 241.922C29.7966 250.18 -49.4993 235.606 -124.68 199.602C-199.86 163.599 -268.391 107.379 -323.67 36.3615L-2.98193 -271.184L430.444 -45.8287Z" fill="#00FF57" fillOpacity="0.14"/>
        </g>
        <g filter="url(#filter1_f_1_13)">
          <path className={styles.path2} d="M1241.02 465.304C1218.8 540.82 1173.82 603.032 1110.4 645.946C1046.99 688.86 967.279 711.03 878.954 710.319C790.628 709.609 696.665 686.042 606.115 641.889C515.565 597.736 431.481 534.486 361.965 458.231L717.811 193.269L1241.02 465.304Z" fill="#58CD4C" fillOpacity="0.37"/>
        </g>
        <g filter="url(#filter2_f_1_13)">
          <path className={styles.path3} d="M1241.02 465.304C1218.8 540.82 1173.82 603.032 1110.4 645.946C1046.99 688.86 967.279 711.03 878.954 710.319C790.628 709.608 696.664 686.041 606.115 641.889C515.565 597.736 431.481 534.485 361.965 458.231L717.811 193.269L1241.02 465.304Z" fill="#58CD4C" fillOpacity="0.37"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_f_1_13" x="-623.67" y="-571.184" width="1354.11" height="1115.35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_1_13"/>
        </filter>
        <filter id="filter1_f_1_13" x="111.965" y="-56.7312" width="1379.05" height="1017.07" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_1_13"/>
        </filter>
        <filter id="filter2_f_1_13" x="111.965" y="-56.7314" width="1379.05" height="1017.07" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_1_13"/>
        </filter>
        <filter id="filter3_f_1_13" x="556.153" y="251.165" width="807.527" height="598.532" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_1_13"/>
        </filter>
      </defs>
    </svg>

  );
};

export default AnimationShadow;